import { BrazeAttributes } from "../../javascript/interface";

export interface AccountInfo {
  logged_in: boolean;
}

export const getAccountInfo = async (): Promise<AccountInfo | null> => {
  const response = await fetch('/api/current_account_info');
  if (response.ok) {
    const json = await response.json();
    return json;
  }
  return {logged_in: false};
}

export const signIn = async (email: string, password: string, cb: (success: boolean, info?: string, account?: any, tracking?: any) => void) => {
  const CSRFToken = document.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
  const data = new FormData();
  data.set('account[account][email]', email);
  data.set('account[account][password]', password);
  data.set('authenticity_token', CSRFToken);
  data.set('utf8', "✓");

  const response = await fetch('/sessions/create_remote', {
    method: 'post',
    body: data
  })

  const body: {success: boolean, info: string, account: any, tracking: any} = await response.json();

  if (body.success) {
    cb(true, body.info, body.account, body.tracking);
  } else {
    cb(false, body.info, body.account, body.tracking);
  }
}

export const signUp = async (
  email: string,
  password: string,
  camapignTag: string,
  campaignToken: string,
  country: string,
  cb: (success: boolean, error: string[]|null, response?:{brazeAttributes: BrazeAttributes, tracking: Object}) => void
) => {
  const CSRFToken = document.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
  const data = new FormData();
  data.set('account[account][email]', email);
  data.set('account[account][password]', password);
  data.set('authenticity_token', CSRFToken);
  data.set('utf8', "✓");

  const origin = `${location.origin}`.replace(/(:\/\/\w+\.)/, `://${country}.`);

  const response = await fetch(`${origin}/accounts/create_remote`, {
    method: "POST",
    body: data
  });

  const responseData = await response.json() as {
    brazeAttributes: BrazeAttributes
    tracking: Object
    errors: {[key: string]: string}
    new_errors: Array<string> | undefined
  };
  const errors = Object.entries(responseData.errors)
  const newErrors = responseData.new_errors

  if (errors.length > 0) {
    cb(false, errors.map((e, _) => `${e[0]} ${e[1][0]}`));
  } else if (newErrors && newErrors.length > 0) {
    cb(false, newErrors);
  } else {
    cb(true, [], responseData);
    // We no longer want to give 48h trial. But keep code just in case
    // activateTrial(camapignTag, campaignToken, origin, (success) => {
    //   if (success) {
    //     cb(true, [], responseData);
    //   } else {
    //     //TODO: How to handle if signup
    //     //succeeded but trial activation failed
    //     cb(true, [], responseData);
    //   }
    // })
  }
}

export const activateTrial = async (camapignTag: string, campaignToken: string, origin: string, cb: (success: boolean) => void) => {
  const params = new URLSearchParams({
    trial_offer: camapignTag,
    campaign_token : campaignToken,
  });

  const response = await fetch(`${origin}/api/go/activate_trial_pre_authed?${params.toString()}`, {
    method: "GET",
    headers: {
      Origin: location.origin,
    }
  })

  const responseData = await response.json()

  cb(responseData.success)
}

export interface EmailValidationResponse {
  exists: boolean
  valid: boolean
  exists_in_country: string
}

export const validateEmail = async (email: string, cb: (result: EmailValidationResponse) => void) => {
  const response = await fetch(`${window.environment.api_v1_endpoint}/email/validate?` + new URLSearchParams({
    email: email,
  }));

  const body: EmailValidationResponse = await response.json();

  cb(body)
}
